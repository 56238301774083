import React from 'react';

const Arrow = () => (
  <svg
    width="30"
    height="14"
    viewBox="0 0 30 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 7H28M28 7L22 1M28 7L22 13" stroke="#F25018" strokeWidth="2" />
  </svg>
);

export default Arrow;
