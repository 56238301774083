import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Text from 'components/UI/Text/Text';

const MARGIN = 32;
export const StyledPromoWrap = styled.ul`
  list-style: none;
`;

export const StyledPromo = styled.li`
  display: inline-block;
  width: 100%;
  margin-bottom: 16px;
  vertical-align: text-top;

  ${MEDIA.TABLET`
    &:not(:last-child) {
      margin-right: ${MARGIN}px;
    }

    ${({ count }) => count >= 4
      ? css`
        width: calc(100% / 2 - ${MARGIN}px);
        margin-bottom: ${MARGIN}px;
      `
      : css`
        width: calc(100% / ${count} - ${count - 2} * ${MARGIN}px);
      `
    }
  `}
`;

export const StyledPromoItem = styled(Text)`
  overflow: hidden;
  margin: 10px 0 0;

  &:first-child {
    margin: 0;
  }
`;
