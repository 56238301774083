import React from 'react';

import Text from '../UI/Text';

import {
  StyledList,
  StyledItem,
  StyledAddress,
  StyledTel,
  StyledEmail,
  StyledWrapper,
  StyledStreet,
} from './styled';

const CONTACTS = [
  {
    city: 'Milan',
    phone: '+39 356 3456 7890',
    address: 'Via Santa Sofía 21,',
    index: '20122 Milano',
    country: 'Italy'
  },
  {
    city: 'Tel Aviv',
    phone: '+972 538 265 514',
    address: 'A.D. Gordon 17,',
    index: '6340723 Tel Aviv',
    country: 'Israel'
  },
  {
    city: 'London',
    phone: '+44 020 7989 8800',
    address: '6 Porter Street ',
    index: 'W1U 6DD UK London',
    country: 'UK'
  },
  {
    city: 'Mexico',
    phone: '+52 1 555 418 0741',
    address: 'Mexico D.F.',
    index: '',
    country: 'Mexico'
  },
];

const List = () => (
  <StyledList>
    <StyledItem>
      <Text as="h4" style={{textTransform: 'uppercase'}} stack>Madrid HQ</Text>
      <StyledAddress>
      {/* TODO CREATE ONE BLOCK */}
        <StyledWrapper>
          <StyledTel href="tel:+34 91 123 0 567" style={{ color: '#F25018' }}>
            +34 91 123 0 567
          </StyledTel>
          <StyledEmail href="mailto:hello@demtech.biz">
            hello@demtech.biz
          </StyledEmail>
        </StyledWrapper>
        <StyledStreet>
          Paseo de la Castellana 171,&nbsp;3ºi
          <br/>28046 Madrid
        </StyledStreet>
      </StyledAddress>
    </StyledItem>
    {CONTACTS.map(contact => (
      <StyledItem key={contact.city}>
        <p style={{textTransform: 'uppercase'}}>{contact.city} </p>
        {/* TODO TRANSFORM TO UPPERCASE */}
        <StyledAddress>
          <StyledTel href={`tel:${contact.phone}`}>{contact.phone}</StyledTel>
          {contact.address}
          <p>{contact.index}</p>
          <p>{contact.country}</p>
        </StyledAddress>
      </StyledItem>
    ))}
  </StyledList>
);

export default List;
