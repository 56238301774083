import React from 'react';

export default () => (
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12142.334816652059!2d-3.690205!3d40.46235!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x39a7d85ed80da89d!2sDemtech!5e0!3m2!1sen!2ses!4v1556529489097!5m2!1sen!2ses"
    width="100%"
    height="450"
    frameborder="0"
    style={{ border:0, margin: '128px 0' }}
    allowfullscreen
  />
);
