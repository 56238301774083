import styled, { css } from 'styled-components';
// import Text from 'components/UI/Text/Text';
import MEDIA from 'helpers/mediaTemplates';

export const SectionStyled = styled.section`
  position: relative;
  ${({ fullWidth }) => !fullWidth && `max-width: 1440px;`}
  margin: 0 auto;
  padding: 64px 24px;

  ${MEDIA.PHONE`
    padding: 48px 24px;
  `}

  ${MEDIA.DESKTOP`
    padding: 120px 160px;
  `}

  ${({ promo }) => promo && css`
    background-color: #000;
    padding-top: 96px !important;
    padding-bottom: 96px !important;
  `}
`;
