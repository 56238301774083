import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { primaryColor } from 'constants/theme';

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  cursor: pointer;
  outline: none;
  transition: all 0.3s linear;

  width: ${({ width }) => (width ? width : 'auto')};
  margin-top: ${({ mt }) => mt && mt};
  margin-right: ${({ mr }) => mr && mr};
  margin-bottom: ${({ mb }) => mb && mb};
  margin-left: ${({ ml }) => ml && ml};

  z-index: 1;

  ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return css`
          width: 15.4rem;
          padding: 1.1rem;
          color: #ffffff;
          background-color: ${primaryColor};

          ${MEDIA.TABLET_XL`
            width: 19.1rem;
            line-height: 2.2rem;
            font-size: 1.6rem;

            &:hover,
            &:focus {
              background-color: #f74000;;
            }
          `}
        `;
      case 'outline':
        return css`
          width: 100%;
          padding: 1.2rem 3rem;
          line-height: 2.4rem;
          font-size: 1.8rem;
          color: ${primaryColor};
          text-transform: none;
          background-color: rgba(242, 80, 24, 0.08);
          border-left: 0.8rem solid ${primaryColor};

          ${({ disabled }) => disabled && css`
            border-color: #aeaeae;
          `}

          ${MEDIA.TABLET_XL`
            line-height: 3.2rem;
            font-size: 2.4rem;
            padding: 1.6rem 3rem;

            &:hover,
            &:focus {
              color: #ffffff;
              background-color: #F74000;
            }
          `}
        `;
    }
  }}

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    background-color: #eee !important;
    color: #ccc;
  `}
`;

export default Button;
