import React from 'react';

import Section from 'components/UI/Section/Section';
import Slider from 'components/UI/Slider/Slider';
import Title from 'components/UI/Title/Title';
import Text from 'components/UI/Text/Text';
import Promo from 'components/UI/Promo/Promo';

import ContentfulRichText
  from 'components/UI/ContentfulRichText/ContentfulRichText';
import ContactsList from 'components/Contacts/List';
import ContactsMap from 'components/Contacts/Map';

import People from './People';
import {
  StyledButton,
  StyledControl,
  StyledArrow,
} from './styled';

const Team = ({ pageData, peopleData, advisorsData }) => {
  const {
    title,
    subtitle,
    description,
    blockInfo,
    blockFeature,
  } = pageData;

  const sliderProps = {
    prevArrow: <StyledControl variant="left" />,
    nextArrow: <StyledControl variant="right" />,
    rows: 2,
    slidesPerRow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          rows: 2,
          slidesPerRow: 3,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 740,
        settings: {
          rows: 1,
          slidesPerRow: 1,
        },
      },
    ],
  };

  return (
    <Section>
      <Title>
          <Text as="h2" line stack>{title}</Text>
          <ContentfulRichText
            richTextAST={subtitle.json}
            contentProps={{
              variant: 'desc',
            }}
          />
          {description && <ContentfulRichText
            richTextAST={description.json}
            contentProps={{
              variant: 'desc',
              mt: 64
            }}
          />}
                </Title>

        <Promo
          title="Our Values"
          data={blockInfo.json.content}
        />

      <Text as="h3" line>Our Team</Text>
      <Slider {...sliderProps}>
        {peopleData.map(item => (<People {...item.node} key={item.id} />))}
      </Slider>
      <Text as="h3" line stack>Advisory board</Text>
      <Slider {...sliderProps}>
        {advisorsData.map(item => (<People {...item.node} key={item.id} />))}
      </Slider>

      <Text as="h3" line>Let's get in touch!</Text>
      <ContactsList />
      <ContactsMap />
    </Section>
  );
};

export default Team;
