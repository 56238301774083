import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

import Text from 'components/UI/Text/Text';

import { StyledPromoWrap, StyledPromo, StyledPromoItem } from './styled';

const Promo = ({
  data = [],
  title
}) => {
  const renderData = data.reduce(
    (acc, { nodeType, value, content }) => {
      // const { nodeType, value } = content[0];
      const val = value || content[0].value;
      const prevItem = acc[acc.length - 1];

      if (nodeType === BLOCKS.HEADING_1) {
        return [...acc, { name: val, desc: [] }];
      } if (acc.length === 0 || !prevItem.name) {
          return [...acc, { desc: [val] }];
      } else {
        prevItem.desc.push(val);
      }

      return acc;
    },
    []
  );

  const count = renderData.length;

  return (
    <StyledPromoWrap>
      {title && <Text as="h3" line>{title}</Text>}
      {renderData.map(({ name, desc }, i) => (
        <StyledPromo key={name} count={count}>
          {name && (
            <StyledPromoItem as="h4" count={count}>
              {name}
            </StyledPromoItem>
          )}
          {desc.map(descItem => (
            <StyledPromoItem variant="desc">{descItem}</StyledPromoItem>
          ))}
        </StyledPromo>
      ))}
    </StyledPromoWrap>
  );
}

export default Promo;
