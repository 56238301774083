import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const StyledList = styled.ul`
  margin-bottom: -64px;
  list-style: none;

  ${MEDIA.TABLET_XL`
    display: flex;
    flex-wrap: wrap;
  `}
`;

export const StyledItem = styled.li`
  width: 29rem;
  margin-bottom: 3.2rem;
  font-weight: 700;
  line-height: 3rem;
  font-size: 2.4rem;
  letter-spacing: -0.05rem;
  color: #181418;

  &:first-child p {
    color: #f25018;
  }

  ${MEDIA.TABLET_XL`
    width: 52%;
    margin-bottom: 6.4rem;
    line-height: 4rem;
    font-size: 3.2rem;

    &:nth-child(odd) {
      width: 48%;
    }

    &:first-child {
      width: 100%;

      & p {
        display: flex;
        flex-wrap: wrap;
      }
    }
  `}
`;

export const StyledAddress = styled.p`
  margin-top: 1.5rem;
  font-weight: 700;
  line-height: 3.4rem;
  font-size: 2.8rem;
  letter-spacing: 0.05rem;
  color: #7A7775;

  ${MEDIA.TABLET_XL`
    line-height: 2.8rem;
    font-size: 2rem;
  `}
`;

export const StyledTel = styled.a`
  display: block;
  color: #7A7775;
  text-decoration: none;
`;

export const StyledEmail = styled.a`
  display: block;
  color: #f25018;
  text-decoration: none;
`;

export const StyledWrapper = styled.div`
  ${MEDIA.TABLET_XL`
    order: 0;
    width: 100%;
  `}
`;

export const StyledStreet = styled.div`
  ${MEDIA.TABLET_XL`
    width: 100%;
  `}
`;
