import styled from 'styled-components';
import Text from 'components/UI/Text/Text';
import Button from 'components/UI/Button/Button';
import SliderControl from 'components/UI/Slider/Control';
import Arrow from './images/Arrow';
import MEDIA from 'helpers/mediaTemplates';

export const StyledTeam = styled.section`
  position: relative;
  max-width: 144rem;
  margin: 0 auto;
  padding: 11.2rem 1.7rem 9.6rem;

  ${MEDIA.TABLET`
    padding: 11.2rem 3rem 7rem;
  `}

  ${MEDIA.TABLET_XL`
    padding: 18.4rem 5rem 16.4rem;
  `}

  ${MEDIA.DESKTOP`
    padding: 18.4rem 12rem 16.4rem;
  `}

  ${MEDIA.DESKTOP_XL`
    padding: 18.4rem 12rem 16.4rem;
  `}
`;

export const StyledTitle = styled.div`
  display: inline-block;
  margin-bottom: 6.4rem;

  ${MEDIA.TABLET_XL`
    padding-left: 4rem;
    margin-bottom: 9.6rem;
  `}
`;

export const StyledList = styled.ul`
  position: relative;
  margin-bottom: 4rem;
  list-style: none;

  display: flex;
  flex-wrap: wrap;
  align-item: center;
  padding: 0 4rem;
  margin-bottom: 1rem;
`;

export const StyledItem = styled(Text)`
  padding: 10px;

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  ${MEDIA.TABLET_XL`
    position: relative;
    width: 31.4%;

    &::before {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      pointer-events: none;
      background-color: rgba(255, 0, 0, 0.2);
    }

    &:hover::before {
      height: 100%;
    }
  `}
`;

export const StyledName = styled(Text)`
  line-height: 52px !important;
  margin: 24px 0;
`;

export const StyledPosition = styled.a`
  font-weight: 400;
  line-height: 2.4rem;
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  color: rgba(24, 20, 24, 0.87);

  ${MEDIA.TABLET_XL`
    line-height: 3.2rem;
    font-size: 1.8rem;
  `}
`;

export const StyledSliderWrap = styled.div`
  flex-grow: 1;
  position: relative;
  width: calc(100% - 16px * 2);
  margin: 0 auto 20px;
`;

export const StyledImage = styled.img`
  position: relative;
  display: block;
  margin-bottom: 2.4rem;
  width: 300px;
  max-width: 100%;
`;

export const StyledButton = styled(Button)`
  ${MEDIA.TABLET`
    width: 32.9rem;
    padding: 2.4rem 3rem;
  `}

  ${MEDIA.TABLET_XL`
    margin-left: 4rem;

    &:hover svg path,
    &:focus svg path {
      stroke: #ffffff;
  `}
`;

export const StyledControl = styled(SliderControl)`
  &::before {
    border-color: #181418;
  }
`;

export const StyledArrow = styled(Arrow)`
  margin-left: 10px;
`;
