import React from 'react';

import { StyledItem, StyledName, StyledPosition, StyledImage } from './styled';

const People = ({ id, name, jobTitle, photo, linkedin }) => (
  <StyledItem variant="h5">
    <StyledImage src={photo.file.url} alt={name} />
    
      <StyledName as="h4">{name}</StyledName>
      <StyledPosition href={linkedin} target="_blank">{jobTitle}</StyledPosition>

  </StyledItem>
);

export default People;
