import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout/Layout';
import Team from 'components/Team/Team';

const TeamPage = ({ data }) => (
  <Layout page="team">
    <Team
      pageData={data.allContentfulPage.edges[0].node}
      peopleData={data.allContentfulPeople.edges}
      advisorsData={data.allContentfulAdvisors.edges}
    />
  </Layout>
);

export default TeamPage;
export const query = graphql`
  query {
    allContentfulPeople(sort:{fields:orderNumber}, filter: { node_locale: { eq: "en-US" }})
    {
      edges {
        node {
          name
          photo {file {url}}
          jobTitle
          id
          linkedin
        }
      }
    }
	allContentfulAdvisors(sort:{fields:orderNumber}){
  	edges{
      node{
        name
        photo {file {url}}
        jobTitle
        id
        linkedin
      }
    }
  }
    allContentfulPage(
      filter: {
        slug: {
          eq: "team"
        }
      }
    ) {
      edges {
        node {
          slug
          title
          subtitle { json }
          description { json }
          blockInfo { json }
          blockFeature { json }
        }
      }
    }
  }
`;
