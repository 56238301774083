import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

const STACK_SIZES = {
  h1: 16,
  h2: 14,
  h3: 12,
  h4: 10,
  h5: 6,
  desc: 4,
  subtitle: 4,
  ul: 0,
};

const Text = styled.p`
  width: ${({ width }) => width || 'auto'};
  ${({ centered }) => centered && `text-align: center;`}

  font-family: Lato, Arial, sans-serif;
  ${({ italic }) => italic && `font-style: italic;`}
  white-space: pre-line;
  color: ${({ dark }) => dark ? '#fff' : '#181818'};
  ${({ uppercase }) => uppercase && css`
    text-transform: uppercase;
    ${MEDIA.TABLET`text-align: center;`}
  `}

  ${({ line, lineColor }) => {
    if (line) {
      // draw line
      return css`
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          background-color: ${lineColor || '#181818'};
        }

        ${({ as, variant }) => ['h4', 'h5', 'h6'].includes(as || variant) ? `
          display: flex;

          &::before {
            position: static;
            width: 4px;
            margin-right: 44px;
            ${MEDIA.TABLET`width: 8px;`}
          }` : ''}
      `;
    }
  }}

  ${({ as, variant, line }) => {
    switch (as || variant) {
      case 'h1':
        return css`
          max-width: 1000px;
          padding: 10px;
          margin-bottom: 20px;

          font-weight: 900;
          font-size: 36px;
          line-height: 42px;

          color: #fff;
          ${'' /* background-color: rgba(242, 80, 24, 0.93); */}

          ${MEDIA.TABLET`
            font-size: 72px;
            line-height: 84px;
          `}
        `;
      case 'h2':
        return css`
          margin: 64px 0;
          font-weight: 900;
          font-size: 36px;
          line-height: 42px;

          ${MEDIA.TABLET`
            font-size: 72px;
            line-height: 84px;
          `}

          ${line && css`
            &::before {
              top: -60px;
              width: 120px;
              height: 16px;
              margin-bottom: 44px;
              ${MEDIA.TABLET`width: 160px;`}
            }
          `}

        `;
      case 'h3':
        return css`
          margin: 50px 0 80px;
          font-weight: 900;
          font-size: 24px;
          line-height: 28px;

          ${MEDIA.TABLET`
            font-size: 48px;
            line-height: 56px;
          `}

          ${line && css`
            &::before {
              top: -40px;
              width: 120px;
              height: 16px;
              margin-bottom: 44px;
              ${MEDIA.TABLET`width: 160px;`}
            }
          `}
        `;
      case 'h4':
        return css`
          margin: 32px 0;
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;

          ${line && css`
            display: flex;
            align-items: center;

            &::before {
              position: static;
              width: 4px;
              height: 64px;
              margin-right: 44px;
              ${MEDIA.TABLET`width: 8px;`}
            }
          `}

          ${MEDIA.TABLET`
            font-size: 36px;
            line-height: 52px;
          `}
        `;
      case 'h5':
        return css`
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;

          ${MEDIA.TABLET`
            font-size: 36px;
            line-height: 48px;
          `}
        `;
      case 'subtitle':
        return css`
          font-size: 18px;
          line-height: 24px;

          ${({ small }) => small && css`
            font-size: 12px;
            line-height: 18px;
            margin: 16px 0;
          `}

          ${MEDIA.TABLET`
            font-size: 24px;
            line-height: 32px;

            ${({ small }) => small && css`
              font-size: 16px;
              line-height: 22px;
            `}
          `}

          ${({ gray }) => gray && css`
            opacity: .6;
          `}
        `;
      case 'desc':
        return css`
          font-size: 18px;
          line-height: 32px;
          margin: 30px 0;

          ${MEDIA.TABLET`
            font-size: 24px;

            ${({ small }) => small && css`
              font-size: 16px;
              line-height: 22px;
              margin: 16px 0;
            `}
          `}

          ${({ maxWidth }) => maxWidth && css`max-width: ${maxWidth};`}
        `;
      case 'label':
        return css`
          font-size: 24px;
          cursor: pointer;
        `;
      case 'li':
      return css`
          margin-left: 2em;

          & > p {
            margin: 20px 0;
          }
        `;
      default:
        return `
          display: inline;
        `;
    }
  }}

  ${({ mt }) => typeof mt === 'number' && `margin-top: ${mt}px !important;`}
  ${({ mb }) => typeof mb === 'number' && `margin-bottom: ${mb}px !important;`}
  ${({ mr }) => typeof mr === 'number' && `margin-right: ${mr}px !important;`}
  ${({ ml }) => typeof ml === 'number' && `margin-left: ${ml}px !important;`}
  ${({ opacity }) => opacity && `opacity: ${opacity} !important;`}
  ${({ bold }) => bold && `font-weight: bold !important;`}
  ${({ bgcolor }) => bgcolor && `background-color: ${bgcolor} !important;`}
  ${({ stack, mt, mb, as, variant }) => {
    if (stack) {
      const margin = STACK_SIZES[(as || variant)];
      return css`margin: ${mt || margin}px 0 ${mb || margin}px`
    }
  }}
`;

export default Text;
